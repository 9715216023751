$primary: #000;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


@import url('https://fonts.googleapis.com/css?family=ZCOOL+QingKe+HuangYou');
// font-family: 'Eater', cursive;
// font-family: 'Special Elite', cursive;
body {
    font-family: 'ZCOOL QingKe HuangYou',
    cursive;
    font-size: 16px;

}
h2,h3{
    font-family: 'ZCOOL QingKe HuangYou',
    cursive;

}

#terms_header h2{
    visibility: hidden;
    
    &:before {
        content: 'Terms Of Use / Disclaimer:';
        visibility: visible;
    }
}

h3 {    
    text-transform: lowercase;  

    &:first-letter {
        text-transform: uppercase;
    }
}

p{
 font-family: 'ZCOOL QingKe HuangYou',
 cursive;

}

html, body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: rgb(230, 230, 230);
    box-shadow: 0px 0px 20px 0px #000;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 32px;
            display: flex;
            align-items: center;
            color: $blk;
            border-radius: $border-radius;
            transition-duration: 0.5s;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        color:#000;
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 50%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 15px;
    }
}

.top-pad {
    padding: 3em;
    background: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 15px;
    }
    
    & ol {
        li {
            font-size: 1em;
        }
        & ol li {
            font-size: 0.75em;
        }
    }
}

.tp-bullets.preview4 .bullet, .tp-bullets.preview4 .bullet:hover, .tp-bullets.preview4 .bullet.selected {
    display: none !important;
}

.banner-v1 {
    background: url(../img/banner/banner_v1.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover;
}

.banner-v2 {
    background: url(../img/banner/banner_v2.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover;
}

.banner-v3 {
    background: url(../img/banner/banner_v3.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover;
}

.banner-v4 {
    background: url(../img/banner/banner_v4.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover;
}

.banner-v5 {
    background: url(../img/banner/banner_v5.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover;
}

.banner-v6 {
    background: url(../img/banner/banner_v6.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover;
}

.banner-v7 {
    background: url(../img/banner/banner_v7.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover;
}

.banner-v8 {
    background: url(../img/banner/banner_v8.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover;
}

.banner-v9 {
    background: url(../img/banner/banner_v9.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover;
}

.banner-v10 {
    background: url(../img/banner/banner_v10.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover;
}

.static-banner {
    position: relative;
    width: 100%;
    height: 780px;
    display: table;
}

.static-banner .banner-content {
    display: table-cell;
    vertical-align: middle;
}

.static-banner .banner-content h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
}

.static-banner .banner-content p {
    font-size: 24px;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}

.static-banner-full {
    position: relative;
    width: 100%;
    height: 100vh;
    display: table;
}

.static-banner-full .banner-content {
    display: table-cell;
    vertical-align: middle;
}

.static-banner-full .banner-content h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
}

.static-banner-full .banner-content p {
    font-size: 24px;
    color: #fff;
    line-height: 31px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}


@media (max-width:480px) {
    .static-banner .banner-content h1, .static-banner-full .banner-content h1 {
        font-size: 48px;
    }
}

.btn-banner {
    width: 64px;
    height: 64px;
    line-height: 64px;
    font-size: 32px;
    border: 2px solid #fff;
    display: inline-block;
    text-align: center;
    margin-top: 50px;
    background: rgba(0, 0, 0, .05);
}

.btn-banner i {
    color: #fff;
}

.btn-banner:hover {
    background-color: rgba(255, 255, 255, .3);
}

.video-banner {
    position: relative;
    background: #000;
}

.fullscreen-bg__video {
    width: 100%;
    height: 100%;
}

.video-content {
    position: absolute;
    top: 30%;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.video-content h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
}

.video-content p {
    font-size: 24px;
    color: #fff;
    margin-bottom: 50px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}

@media (max-width:568px) {
    .video-content h1 {
        font-size: 26px;
    }
    .video-content p {
        font-size: 16px;
    }
    .video-content .btn-banner {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 21px;
    }
}

.flexslider .slides>li {
    position: relative;
}

.flex-caption {
    bottom: 30px;
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    margin: 0;
    padding: 25px 25px 25px 30px;
    position: absolute;
    left: 30px;
    width: 295px;
}

.flex-caption h3 {
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.flex-caption p {
    font-size: 14px !important;
    line-height: 22px;
    font-weight: 300;
    color: #fff;
}

.color-white {
    color: #fff !important;
}

.color-black {
    color: #3a3a3a;
}

.btn {
    background-color: $primary;
    // border: 3px solid $primary;
    font-size: 15px;
    padding: 1em 1em;
    color: #fafafa;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition-duration: 0.5s;
   
    &:hover {
    background-color: $wht;
    color: $blk;

        @media (max-width: 1366px){
         background-color: $primary;
          color: $wht;
        }
    }

    &:active, &:focus {
        background-color: $primary;
          color: $wht;
    }
}

.bannercontainer {
    // max-width: 1400px;
    // margin: 0 auto;
    // width: 100%;
    // border: 2px solid #000 !important;
    // border: 8px solid #00F;
    position: relative;
    padding: 0
}

.bannercontainer .banner {
    width: 100%;
    position: relative;
}

.divider1{   
    // background: url(../img/banner.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-attachment: fixed;
    padding: 25px 0px;

    @media (max-width: 1366px){
        background-attachment: unset;
    }

    @media (max-width: 767px) {
         background-position: center;
    }

    .logo2{
        max-width: 300px;
        padding-top: 50px;


        @media (max-width: 767px){
            width: 250px;
        }
    }

    h1{
        color: #fff;
    }

    p {
        padding-top: 25px;
        font-size: 19px;
        line-height: 30px;
        color: #fff;

        @media (max-width: 767px){
            padding-top: 15px;
            font-size: 15px;
            line-height: 22px;
        }         
    }

}

.divider2{   
    background: #333;
    padding: 150px 0px;

    @media (max-width: 767px){
        padding: 50px 0px;
        }
   
   h1 {
        color: #fff;
        text-shadow: 2px 2px #000;

        @media (max-width: 767px){
            font-size: 25px;
        }
    }

    p {
        font-size: 18px;
        color: #fff;         
    }
}

.void {
    visibility: hidden;
}

.padTop{
    padding-top: 349px;
}

i.fa {
    color:$primary;
    font-size: 100px;
        @media (max-width: 800px) {
           font-size: 75px;
        }
       @media (max-width: 768px) {
           font-size: 60px;
        }
}

.form {
    background: #000;

    h1{
        font-size: 30px;
        text-shadow: 2px 2px #000;
        color: #fff;
    }
}

.img1 {
    //background: url(../img/img1.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/img1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 15%;
    padding: 61px 0px;
}

.img2 {
    // background: url(../img/img2.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/img2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 40%;
    padding: 0px 0px;
}

.img3 {
    // background: url(../img/img3.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/img3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position:top center;
    padding: 61px 0px;
}


.img4 {
    //background: url(../img/img4.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/img4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding: 298px 0px;
}

.img5 {
    // background: url(../img/img5.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/img5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 15%;
    padding: 298px 0px;

    @media (max-width: 767px){
        padding: 150px 0px;
    }
    @media (max-width:500px){
        padding: 70px 0px;
    }
}

.img6 {
    // background: url(../img/img6.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/img6.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 5%;
    padding: 298px 0px;
}

.outro {
    //background: url(../img/img1.jpg);
    // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/banner2.jpg);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: 50% 0%;
    // padding: 100px 0px 100px 0px;
    position: relative;
    background: #000;

    .blurb{
        max-width: 800px;
        position: absolute;
        margin: auto 0px;
        top: 35%;
        z-index: 500;
        left: 29%;
        
        @media (max-width: 1660px) {
               left: 25%;
        }

        @media (max-width: 1366px) {
               left:  20%;
        }

        @media (max-width: 1200px) {
               left: 17%;
        }
        
        @media (max-width: 1024px) {
               left: 12%;
        }

        @media (max-width: 991px) {
               left: 10%;
        }

        @media (max-width: 800px) {
               left: 0%;
        }

        @media (max-width: 768px) {
               left: 0%;
        }
    }

    p{
        font-size: 19px;
        line-height: 30px;
        color: #fff;

        @media (max-width: 767px){
            padding-top: 15px;
            font-size: 15px;
            line-height: 20px;
        }   
    }
}